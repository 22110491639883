<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{
        route.path.includes("documents")
          ? t("headings.create_document")
          : t("headings.create_template")
      }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card p-4">
      <div class="p-4">
        <form-steps :currentStep="currentStep" :steps="steps" />
      </div>
      <div v-if="currentStep == 0">
        <div
          class="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        >
          <div
            class="flex cursor-pointer items-center justify-center rounded-md border border-slate-300 py-20 text-sm font-semibold"
            :class="{
              'text-slate-500':
                selectedTemplate != 'empty' || company.primaryText != 'light',
              'text-white':
                selectedTemplate == 'empty' && company.primaryText == 'light',
            }"
            :style="{
              backgroundColor:
                selectedTemplate == 'empty' ? company.primary[500] : null,
              borderColor: hovers[0] ? company.primary[500] : null,
            }"
            @click="selectTemplate('empty')"
            @mouseover="hovers[0] = true"
            @mouseleave="hovers[0] = false"
          >
            {{ t("labels.empty_document") }}
          </div>
          <div
            v-for="(template, index) in templates"
            :key="template.id"
            class="flex cursor-pointer items-center justify-center rounded-md border border-slate-300 py-20 text-sm font-semibold"
            :class="{
              'text-slate-500':
                selectedTemplate != template || company.primaryText != 'light',
              'text-white':
                selectedTemplate == template && company.primaryText == 'light',
            }"
            :style="{
              backgroundColor:
                selectedTemplate == template ? company.primary[500] : null,
              borderColor: hovers[index + 1] ? company.primary[500] : null,
            }"
            @click="selectTemplate(template)"
            @mouseover="hovers[index + 1] = true"
            @mouseleave="hovers[index + 1] = false"
          >
            {{ template.name }}
          </div>
        </div>
        <hr class="my-3 border-gray-300" />
        <div class="flex justify-end">
          <form-button
            :command="() => nextStep()"
            :disabled="waiting || !selectedTemplate"
            id="submit-button"
            label="next"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
      </div>
      <div v-else-if="currentStep == 1">
        <div class="mb-3 grid grid-cols-1 gap-3 md:grid-cols-3">
          <div class="w-full">
            <label>{{ t("labels.document_name") }}</label>
            <form-input
              class="w-full"
              id="document-name-input"
              :invalid="state.name.error ? true : false"
              type="text"
              v-model="state.name.value"
            />
          </div>
          <div class="w-full">
            <label>{{ t("labels.document_type") }}</label>
            <form-select
              id="document-type-select"
              :invalid="state.classificationId.error ? true : false"
              :optionLabel="['label']"
              :options="classifications"
              translatableLabel="classifications"
              v-model="state.classificationId.value"
            />
          </div>
        </div>
        <editor v-model="state.content.value" />
        <hr class="my-3 border-gray-300" />
        <div class="flex justify-between">
          <form-button
            :command="() => previousStep()"
            :disabled="waiting"
            id="previous-button"
            label="previous"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
          <div class="flex gap-3">
            <form-button
              :command="() => saveTemplate()"
              :disabled="waiting"
              id="submit-button"
              label="save_as_template"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
            />
            <form-button
              :command="() => nextStep()"
              :disabled="waiting"
              id="next-button"
              label="next"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div></div>
        <hr class="my-3 border-gray-300" />
        <div class="flex justify-between">
          <form-button
            :command="() => previousStep()"
            :disabled="waiting"
            id="previous-button"
            label="previous"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
          <form-button
            :command="() => save()"
            :disabled="waiting"
            id="submit-button"
            label="save_and_send"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import Editor from "../components/editor/Editor.vue";
import FormButton from "../components/form/FormButton.vue";
import FormInput from "../components/form/FormInput.vue";
import FormSelect from "../components/form/FormSelect.vue";
import FormSteps from "../components/form/FormSteps.vue";
import validate from "../utils/validators";
export default {
  components: {
    Breadcrumb,
    Editor,
    FormButton,
    FormInput,
    FormSelect,
    FormSteps,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();

    const classifications = computed(
      () => store.state.document.classifications
    );
    const company = computed(() => store.state.company.company);
    const estate = computed(() => store.state.estate.estate);
    const templates = computed(() => store.state.document.templates);

    const waiting = computed(() => {
      if (store.state.document.waiting) return true;
      if (store.state.estate.waiting) return true;
      return false;
    });

    const state = reactive({
      classificationId: {
        error: null,
        rules: ["isRequired"],
        value: null,
      },
      content: {
        error: null,
        rules: [],
        value: "",
      },
      name: {
        error: null,
        rules: ["isRequired"],
        value: "",
      },
    });

    const selectedTemplate = ref(null);

    const selectTemplate = (template) => {
      selectedTemplate.value = template;
    };

    const currentStep = ref(0);
    const steps = [
      { label: "template" },
      { label: "editor" },
      { label: "send" },
    ];

    const nextStep = async () => {
      if (currentStep.value == 0) {
        setState();
      } else if (currentStep.value == 1) {
        const isValid = await isFormValid();
        if (!isValid) return;
      }
      currentStep.value++;
    };

    const previousStep = () => {
      currentStep.value--;
    };

    const hovers = ref([]);

    const setState = () => {
      if (selectedTemplate.value == "empty") {
        state.classificationId.value = null;
        state.content.value = "";
      } else {
        state.classificationId.value = selectedTemplate.value.classificationId;
        state.content.value = JSON.parse(selectedTemplate.value.content);
      }
    };

    const isFormValid = async (isValid = true) => {
      for (const [_, value] of Object.entries(state)) {
        value.error = await validate(value.rules, value.value);
        if (value.error) isValid = false;
      }
      return isValid;
    };

    const save = () => {
      const data = {
        classificationId: state.classificationId.value,
        companyId: company.value.id,
        content: JSON.stringify(state.content.value),
        estateId: estate.value.id,
        name: state.name.value,
      };
      store.dispatch("document/createDocument", data);
    };

    const saveTemplate = async () => {
      const isValid = await isFormValid();
      if (!isValid) return;
      const data = {
        classificationId: state.classificationId.value,
        companyId: company.value.id,
        content: JSON.stringify(state.content.value),
        estateId: estate.value.id,
        name: state.name.value,
      };
      store.dispatch("document/createTemplate", data);
    };

    onMounted(() => {
      if (!classifications.value.length) {
        store.dispatch("document/getClassifications");
      }
      if (!templates.value.length) {
        store.dispatch("document/getTemplates", estate.value.id);
      }
    });

    return {
      classifications,
      company,
      currentStep,
      estate,
      hovers,
      nextStep,
      previousStep,
      route,
      save,
      saveTemplate,
      selectedTemplate,
      selectTemplate,
      state,
      steps,
      t,
      templates,
      waiting,
    };
  },
};
</script>
